/* Sliding Pane Container */
.sliding-pane {
  position: fixed;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  background-color: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);  /* shadow-lg */
  transform: translateX(0);  /* when open */
  transform: translateX(100%);  /* when closed */
  transition: transform 300ms ease-in-out;
}

/* Overlay */
.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);  /* bg-black bg-opacity-30 */
  transition: opacity 300ms;
}

/* Header */
.pane-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1.5rem;  /* px-6 py-4 */
  border-bottom: 1px solid rgb(229, 231, 235);  /* border-b */
}

.header-title {
  font-size: 1.25rem;  /* text-xl */
  font-weight: 600;    /* font-semibold */
  color: rgb(31, 41, 55);  /* text-gray-800 */
}

/* Tabs */
.tabs-container {
  border-bottom: 1px solid rgb(229, 231, 235);  /* border-b */
  display: flex;
}

.tab-button {
  padding: 0.75rem 1.5rem;  /* px-6 py-3 */
  font-size: 0.875rem;     /* text-sm */
  font-weight: 500;        /* font-medium */
  border-bottom: 2px solid transparent;
  transition: colors 150ms;
}

.tab-button.active {
  color: rgb(31, 41, 55);  /* text-gray-800 */
  border-bottom-color: rgb(22, 163, 74);  /* border-green-600 */
}

.tab-button:hover:not(.active) {
  color: rgb(55, 65, 81);  /* hover:text-gray-700 */
  border-bottom-color: rgb(209, 213, 219);  /* hover:border-gray-300 */
}

/* Form Fields */
.form-label {
  display: block;
  font-size: 0.875rem;  /* text-sm */
  font-weight: 500;     /* font-medium */
  color: rgb(55, 65, 81);  /* text-gray-700 */
  margin-bottom: 0.25rem;  /* mb-1 */
}

.form-input {
  width: 100%;
  padding: 0.5rem;  /* p-2 */
  border: 1px solid rgb(209, 213, 219);  /* border-gray-300 */
  border-radius: 0.375rem;  /* rounded-md */
}

.form-input:focus {
  outline: none;
  ring: 1px rgb(34, 197, 94);  /* focus:ring-1 focus:ring-green-500 */
  border-color: rgb(34, 197, 94);  /* focus:border-green-500 */
}

/* Location Tab Message */
.location-message {
  font-size: 0.875rem;  /* text-sm */
  color: rgb(75, 85, 99);  /* text-gray-600 */
}