@tailwind base;
@tailwind components;
@tailwind utilities;

.nav-link {
    @apply hover:text-green-600 transition duration-150;
}

.primary-button {
    @apply bg-green-600 text-white px-6 py-3 rounded-md hover:bg-green-700 transition duration-150;
}

.secondary-button {
    @apply border border-green-600 text-green-600 px-6 py-3 rounded-md hover:bg-green-50 transition duration-150;
}

.card {
    @apply bg-white p-6 rounded-lg shadow-md text-center transition duration-150 hover:shadow-lg;
}

.section-title {
    @apply text-3xl font-bold text-center mb-12;
}

.footer-link {
    @apply text-gray-400 hover:text-white transition duration-150;
}
