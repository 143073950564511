.ReactModal__Content{
  transform: translateX(100%);
  transition: all 500ms ease-in-out;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Content--before-close {
  opacity: 1;
  transform: translateX(100%);
}