/* Custom styles for consistent box shadows */
.shadow-sm {
 box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

/* Smooth transitions */
.transition-all {
 transition-property: all;
 transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
 transition-duration: 300ms;
}

/* Custom border styles */
.border-b {
 border-bottom-width: 1px;
 border-color: #E5E7EB;
}

.border-t {
 border-top-width: 1px;
 border-color: #E5E7EB;
}

/* Custom scrollbar for the main content area */
.overflow-y-auto {
 scrollbar-width: thin;
 scrollbar-color: #E5E7EB transparent;
}

.overflow-y-auto::-webkit-scrollbar {
 width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
 background: transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
 background-color: #E5E7EB;
 border-radius: 3px;
}

.list-container {
  max-width: 80rem;
}