.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 600px; */
  width: 100%;
  padding: 1rem 1rem 1rem 1rem;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  border-radius: 4px;
  box-sizing: border-box;
  font-family: Roboto;
  font-weight: 400;
}

.upload_started {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-family: 'Roboto';
  font-weight: 400;
}


.max_files_number_exceeded {
  width: 100%;
  text-align: center;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 0.9rem;
  color: var(--color-red-1);
  padding: 1rem 0 0;
  box-sizing: border-box;
}

.disclaimer {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  font-family: 'Roboto';
  font-weight: 400;
}

.canvas_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: Roboto;
  font-weight: 400;
}

.files_list_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: Roboto;
  font-weight: 400;
  /* border-bottom: 1px solid var(--light-greye4);
  padding-bottom: 0.5rem; */
  /* margin-bottom: 1.5rem; */
  /* max-height: calc(100vh - 27rem);
  overflow-y: auto; */
}

.files_list_progress_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1rem 0;
  font-family: Roboto;
  font-weight: 400;
}

.upload_button {
  min-width: 100px;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: var(--light-black12);;
  /* background-color: #0073cf; */
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  font-weight: 500;
  line-height: 1.5;
  text-decoration: none;
  font-family: Roboto;

  &.disabled {
    background-color: #ccc;
    color: #fff;
    cursor: not-allowed;
  }
}
