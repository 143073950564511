@import url(https://cdn.jsdelivr.net/gh/tonsky/FiraCode@1.206/distr/fira_code.css);
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype')
}
@font-face {
  font-family: 'AtlasGroteskWeb';
  src: url('./assets/fonts/43372a4ce8dc3abbca31595815be7cf3.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'AtlasGroteskWeb';
  src: url('./assets/fonts/e4d50a7badededefb0d33b088efc3f4f.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Hw Cigars';
  src: url('./assets/fonts/HWCigarsRegular.woff2') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

:root {
  --color-grey-00: #f5f5f5;
  --color-grey-01: #d3d3d3;
  --color-blue0: #e8f0fd;
  --color-blue1old: #2763e9;
  --color-blue1:#004182;
  --color-blue2: #004182;
  --color-blue3: #09223b;
  --color-blue4: #f6f9fe;
  --color-red0: #ba261a;
  --color-red1: #faeff1;
  --color-red2: #dc3545;
  --color-red3: #eec8cf;
  --color-gray0: #e6e6e6;
  --color-gray1: #999999;
  --color-gray2: #cccccc;
  --color-gray3: #eee;
  --color-green0: #effaf1;
  --color-green1: #28a745;
  --color-green2: #c8eecf;
  --light-black12: #121212;
  --white: #fff;
  --light-greye4: #e4e4e4;
  --drop-shadow: rgba(0,0,0,.08);
  --max-content-width: 85rem;
  --color-green-0: #CCF1D4;
  --color-green-1: #00BB29;
  --color-gray-0: #C2C2C2;
  --color-gray-1: #808080;
  --color-gray-2: #eeeeee;
  --color-red-0: #F5CCCC;
  --color-red-1: #B50001;
  --color-green-0: #CCF1D4;
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

.separator {
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--color-gray2);
}
