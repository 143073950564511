.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: Roboto;
  font-weight: 400;
}

.banner_text {
  font-size: 1.5rem;
  color: #ccc;
  display: block;
  margin: 0.5rem 0;
  font-family: Roboto;
  font-weight: 400;
}

.banner {
  background-color: #fafafa;
  width: 100%;
  border: 4px dashed #ccc;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

.input {
  width: 100%;
  height: 100%;
  display: none;
}

.warning {
  width: 100%;
  text-align: left;
  margin-left: -8px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 0.9rem;
  color: var(--color-red-1);
  margin-bottom: 0.5rem;
}